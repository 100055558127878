'use client';

import styled from 'styled-components';
import { Link } from 'src/i18n/routing';
import { TABS } from '../../constants';
import { useTranslations } from 'next-intl';

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  border-radius: 50%;
  text-align: left;
`;

const Go = styled.span`
  color: #ccc;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  &:hover {
    h2 {
      text-decoration: underline;

      ${Go} {
        color: #ffb144;
      }
    }
    p {
      color: black;
    }
  }
  color: black;

  .misc {
    font-size: 1.2em;
    margin-top: 2.1em;
    color: darkslategray;
  }
`;

const Outer = styled.div`
  p {
    margin-top: 0.5em;
    color: #777;
    // font-size: 0.9em;
  }

  h1,
  h2 {
    margin-bottom: 0em;
  }
`;

function TopLevelView() {
  const t = useTranslations();

  return (
    <Outer>
      <hgroup>
        <h1>{t('topLevelView.heading')}</h1>
        <p>{t('topLevelView.subtitle')}</p>
        <p>{t('topLevelView.description')}</p>
      </hgroup>
      <Container>
        {Object.values(TABS).map((tab) => (
          <li key={tab.slug}>
            <StyledLink href={`/${tab.slug}`}>
              <h2>
                {t(`breadcrumbs.${tab.i18nKey}.name`)} <Go>⇒</Go>
              </h2>
              <p>{t(`breadcrumbs.${tab.i18nKey}.description`)}</p>
            </StyledLink>
          </li>
        ))}
        <li key="latest-updates">
          <StyledLink href="latest-updates">
            <h2 className="misc">
              {t('breadcrumbs.latestUpdates.name')} <Go>⇒</Go>
            </h2>
            <p>{t('breadcrumbs.latestUpdates.description')}</p>
          </StyledLink>
        </li>
      </Container>
    </Outer>
  );
}

export default TopLevelView;
