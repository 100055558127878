export type TabName = 'meditations' | 'resources' | 'talks';

interface Tab {
  slug: string;
  // Nested key under breadcrumbs. breadcrumbs.${i18nKey}
  i18nKey: TabName;
}

export const TABS: { [key: string]: Tab } = {
  meditations: {
    slug: 'meditations',
    i18nKey: 'meditations',
  },
  talks: {
    slug: 'talks',
    i18nKey: 'talks',
  },
  resources: {
    slug: 'resources',
    i18nKey: 'resources',
  },
};
